html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-width: 360px;
  background: #ffffff;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

a {
  line-height: inherit;
  color: #73a200;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
a:hover,
a:focus {
  color: #638b00;
}
a img {
  border: 0;
}

button {
  border: 0;
  cursor: pointer;
}
